@import 'reset.css';
:root {
  --vh: 100%;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}
main {
  position: relative;
  margin: 0 auto;
  width: 600px;
  height: 100vh;
  height: var(--vh);
}
@media only screen and (max-device-width: 600px) {
  main {
    width: 100%;
    max-width: 100%;
  }
}
main div.buttons {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 10px 0;
  background: #fff;
  z-index: 100;
}
@media only screen and (max-device-width: 600px) {
  main div.buttons {
    padding-right: 5px;
    padding-left: 5px;
  }
}
main div.buttons > button {
  padding: 5px 9px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #3a3a3a;
  outline: none;
  user-select: none;
}
main div.buttons > button:hover {
  background-color: #eeeef7;
  cursor: pointer;
}
main div.buttons > button + button {
  margin-left: 10px;
}
main div.buttons > button > span:not(.rc-checkbox) {
  margin-left: 9px;
}
main div.item-list {
  height: calc(100vh - 65px);
  height: calc(var(--vh) - 65px);
  padding-top: 60px;
  overflow-y: scroll;
}
@media only screen and (max-device-width: 600px) {
  main div.item-list {
    height: var(--vh);
    padding-bottom: 60px;
  }
}
main div.item-list > .item {
  position: relative;
  display: block;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
@media only screen and (max-device-width: 600px) {
  main div.item-list > .item {
    padding-right: 15px;
    padding-left: 15px;
  }
}
main div.item-list > .item:hover {
  background-color: #f2f4f7;
  cursor: pointer;
}
main div.item-list > .item:hover > .delete-button {
  display: block;
}
main div.item-list > .item.-done > .content {
  color: #9a9a9a;
}
main div.item-list > .item > input[type="checkbox"],
main div.item-list > .item > span.rc-checkbox {
  margin-top: 5px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}
main div.item-list > .item > .content {
  width: 100%;
  font-size: 20px;
  line-height: 24px;
  user-select: none;
}
main div.item-list > .item > button.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
  width: 30px;
  padding: 0;
  background-color: transparent;
  border: none;
  color: #9a9a9a;
  font-size: 14px;
  line-height: 30px;
  outline: none;
  user-select: none;
  z-index: 100;
}
main div.item-list > .item > button.delete-button:hover {
  cursor: pointer;
  color: #4a4a4a;
}
main div.item-list > .message {
  padding: 30px 10px;
  color: #9a9a9a;
  text-align: center;
}
main form.todo-form {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
main form.todo-form > div.line {
  position: relative;
  width: 100%;
}
main form.todo-form > div.line > button {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 40px;
  height: 40px;
  background: #88888b;
  border: none;
  border-radius: 4px;
  color: #e1eaf3;
  font-size: 18px;
  text-align: center;
  outline: none;
}
main form.todo-form > div.line > button:hover {
  background: #838386;
  cursor: pointer;
}
main form.todo-form > div.line > button > svg {
  margin-top: 6px;
}
main form.todo-form > div.line > input {
  margin-bottom: 5px;
  width: 100%;
  padding: 10px 50px 10px 15px;
  border: 1px solid #ccc;
  font-size: 20px;
}
@media only screen and (max-device-width: 600px) {
  main form.todo-form > div.line > input {
    margin-bottom: 0;
    border-right: none;
    border-left: none;
  }
}
main form.todo-form > div.line > input:focus {
  outline: none;
}
